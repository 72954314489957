import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  AddPaymentStatus,
  getLatestActivityInvoiceTime,
  getInvoices,
  getTallyInvoices,
  getAllCompanyList,
} from "../../../../redux/admin/action";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import { createSearchParams } from "react-router-dom";

const label = { inputProps: { "aria-label": "Switch demo" } };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function TallyInvoiceComponent() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { activeCompany } = Index.useSelector((state) => state.AdminReducer);

  const [loading, setLoading] = useState(false);

  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [invoicesData, setInvoicesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [selectedCompany, setSelectedCompany] = useState("");
  // const [serachedDateRange, setSearchedDateRange] = useState("");
  const [searchingText, setSearchingText] = useState("");
  const [serachedDateRange, setSearchedDateRange] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf("month"));
  const [companyList, setCompanyList] = useState([]);

  const handleGetInvoiceDetails = (values) => {
    setLoading(true);
    dispatch(getTallyInvoices({ ...values }))
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setTotalRecordCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
          setInvoicesData(res?.data);
        } else {
          setLoading(false);
          setTotalRecordCount(0);
          setCurrentPage(1);
          setInvoicesData([]);
        }
      })
      .catch((error) => console.error(error));
  };

  // const handleSearch = React.useCallback(
  //   (e) => {
  //     const { value } = e.target;
  //     setSearchingText(value);
  //     if (value?.length > 0) {
  //       const values = {
  //         page: 1,
  //         search: value,
  //         size: paginationPerPage,
  //         companyName: selectedCompany,
  //         to:
  //           serachedDateRange?.length > 0 && serachedDateRange[0] !== null
  //             ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
  //             : "",
  //         from:
  //           serachedDateRange?.length > 0 && serachedDateRange[1] !== null
  //             ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
  //             : "",
  //       };
  //       handleGetInvoiceDetails(values);
  //     } else {
  //       const values = {
  //         page: 1,
  //         search: "",
  //         size: paginationPerPage,
  //         companyName: selectedCompany,
  //         to:
  //           serachedDateRange?.length > 0 && serachedDateRange[0] !== null
  //             ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
  //             : "",
  //         from:
  //           serachedDateRange?.length > 0 && serachedDateRange[1] !== null
  //             ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
  //             : "",
  //       };
  //       handleGetInvoiceDetails(values);
  //     }
  //   },
  //   [paginationPerPage, selectedCompany, serachedDateRange, setSearchingText]
  // );

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        companyName: selectedCompany,
        to:
          serachedDateRange?.length > 0 && serachedDateRange[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0 && serachedDateRange[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
      };
      handleGetInvoiceDetails(values);
    },
    [paginationPerPage, serachedDateRange, setCurrentPage, selectedCompany]
  );

  // when the get all the company list then used...
  const handleGetAllCompanyList = () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            companyName: res?.data[0].tallyCompanyName,
            to:
              serachedDateRange?.length > 0 && serachedDateRange[0] !== null
                ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0 && serachedDateRange[1] !== null
                ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
                : "",
          };
          setSelectedCompany(res?.data[0].tallyCompanyName);
          handleGetInvoiceDetails(values);

          dispatch(getLatestActivityInvoiceTime());
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        console.error(error);
      });
  };

  useEffect(() => {
    handleGetAllCompanyList();
    // const values = {
    //   page: 1,
    //   search: "",
    //   size: paginationPerPage,
    //   companyName: selectedCompany,
    //   to:
    //     serachedDateRange?.length > 0 && serachedDateRange[0] !== null
    //       ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
    //       : "",
    //   from:
    //     serachedDateRange?.length > 0 && serachedDateRange[1] !== null
    //       ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
    //       : "",
    // };

    // handleGetInvoiceDetails(values);
  }, []);

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      size: value,
      search: searchingText,
      companyName: selectedCompany,
      to:
        serachedDateRange?.length > 0 && serachedDateRange[0] !== null
          ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0 && serachedDateRange[1] !== null
          ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
          : "",
    };
    handleGetInvoiceDetails(values);
  };

  //   when the user change the company then used...
  const handleChangeCompany = (event) => {
    if (event.target.value?.length) {
      setSelectedCompany(event.target.value);
      const values = {
        page: 1,
        size: paginationPerPage,
        companyName: event.target.value,
        search: searchingText,
        to:
          serachedDateRange?.length > 0 && serachedDateRange[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0 && serachedDateRange[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
      };
      handleGetInvoiceDetails(values);
    } else {
      setSelectedCompany("");
      const values = {
        page: 1,
        size: paginationPerPage,
        companyName: "",
        search: searchingText,
        to:
          serachedDateRange?.length > 0 && serachedDateRange[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0 && serachedDateRange[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
      };
      handleGetInvoiceDetails(values);
    }
  };

  // when the user change the date then used...
  // const handleChangeDate = React.useCallback(
  //   (data) => {
  //     setSearchedDateRange(data);
  //     if (data[0] !== null && data[1] !== null) {
  //       const values = {
  //         page: 1,
  //         size: paginationPerPage,
  //         companyName: selectedCompany,
  //         to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
  //         from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
  //         search: searchingText,
  //       };
  //       handleGetInvoiceDetails(values);
  //     } else if (data[0] === null && data[1] === null) {
  //       const values = {
  //         page: 1,
  //         size: paginationPerPage,
  //         companyName: selectedCompany,
  //         search: searchingText,
  //         to:
  //           serachedDateRange?.length > 0 && serachedDateRange[0] !== null
  //             ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
  //             : "",
  //         from:
  //           serachedDateRange?.length > 0 && serachedDateRange[1] !== null
  //             ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
  //             : "",
  //       };
  //       handleGetInvoiceDetails(values);
  //     }
  //   },
  //   [setSearchedDateRange, selectedCompany, paginationPerPage]
  // );

  const handleChangeDate = React.useCallback(
    (data) => {
      setSelectedDate(data);

      const startOfMonth = dayjs(data).startOf("month").format("YYYY-MM-DD");
      let endOfMonth;
      const selectedMonth = data.month();
      const selectedYear = data.year();
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      if (selectedMonth === currentMonth && selectedYear === currentYear) {
        endOfMonth = dayjs().format("YYYY-MM-DD"); // Current date if it's the current month
      } else {
        endOfMonth = data.endOf("month").format("YYYY-MM-DD"); // End of the selected month
      }
      setSearchedDateRange([startOfMonth, endOfMonth]);
      if (startOfMonth !== null && endOfMonth !== null) {
        const values = {
          page: 1,
          size: paginationPerPage,
          search: searchingText,
          to: startOfMonth !== null ? startOfMonth : "",
          from: endOfMonth !== null ? endOfMonth : "",
          companyName: selectedCompany,
        };
        handleGetInvoiceDetails(values);
      }
    },
    [serachedDateRange, paginationPerPage, selectedCompany]
  );

  const handlePaymentstatus = (id) => {
    const data = new URLSearchParams();
    // setLoadingRowId(id);
    data.append("id", id);
    dispatch(AddPaymentStatus(data)).then((res) => {
      if (res?.status === 200) {
        toast.success(res.message);
        const values = {
          page: 1,
          size: paginationPerPage,
          companyName: selectedCompany,
          search: searchingText,
          to:
            serachedDateRange?.length > 0 && serachedDateRange[0] !== null
              ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0 && serachedDateRange[1] !== null
              ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
              : "",
        };
        handleGetInvoiceDetails(values);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <Index.DynamicTitle title="Invoice Management" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Invoice Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearch(e)}
                    value={searchingText}
                  />
                </Search> */}
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    {/* <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                        slotProps={{ textField: { size: "small" } }}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            textField: {
                              readOnly: true,
                              size: "small",
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider> */}
                    <Index.LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["year", "month"]}
                        minDate={dayjs("2024-01-01")}
                        maxDate={dayjs()}
                        value={selectedDate}
                        onChange={(newValue) => handleChangeDate(newValue)}
                        renderInput={(params) => (
                          <Index.TextField {...params} helperText={null} />
                        )}
                      />
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
                <Index.Box>
                  <Index.Box className="input-design-div with-border">
                    <Index.Select
                      className="placeholder-set-text-box"
                      fullWidth
                      name="companyName"
                      displayEmpty
                      inputProps={{ "aria-label": "without label" }}
                      // defaultValue={values ? values?.portLimit : ""}
                      value={selectedCompany}
                      MenuProps={MenuProps}
                      onChange={(event) => handleChangeCompany(event)}
                    >
                      <Index.MenuItem value={""}>
                        <Index.MenuItem disabled className="all-select-label">
                          Select Company
                        </Index.MenuItem>
                      </Index.MenuItem>

                      {companyList?.map((item) => (
                        <Index.MenuItem
                          key={item?._id}
                          value={item?.tallyCompanyName}
                          disabled={
                            item?.tallyCompanyName?.length <= 0 ? true : false
                          }
                        >
                          {item?.tallyCompanyName}
                        </Index.MenuItem>
                      ))}
                    </Index.Select>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "YYYY-MM-DD"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/new-invoice-export",
                        `Tally_Invoice_List_${curDate}`,
                        {
                          search: searchingText,
                          to:
                            serachedDateRange?.length > 0
                              ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
                              : "",
                          from:
                            serachedDateRange?.length > 0
                              ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
                              : "",
                          companyName: selectedCompany,
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <Index.Box className="barge-common-box box-margin">
              {loading ? (
                <>
                  <Index.Box className="bank-dash-loader">
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.Box>
                </>
              ) : (
                <Index.Box className="mini-card-main mini-card-main-bank-details card-wrapper">
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total IGST
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {invoicesData?.length
                          ? invoicesData?.[0]?.hasOwnProperty("totalIgst")
                            ? parseFloat(
                              invoicesData?.[0]?.totalIgst < 0
                                ? invoicesData?.[0]?.totalIgst * -1
                                : invoicesData?.[0]?.totalIgst
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(0).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total CGST
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {invoicesData?.length
                          ? invoicesData?.[0]?.hasOwnProperty("totalCgst")
                            ? parseFloat(
                              invoicesData?.[0]?.totalCgst < 0
                                ? invoicesData?.[0]?.totalCgst * -1
                                : invoicesData?.[0]?.totalCgst
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(0).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total SGST
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {invoicesData?.length
                          ? invoicesData?.[0]?.hasOwnProperty("totalSgst")
                            ? parseFloat(
                              invoicesData?.[0]?.totalSgst < 0
                                ? invoicesData?.[0]?.totalSgst * -1
                                : invoicesData?.[0]?.totalSgst
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(0).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total TDS
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {invoicesData?.length
                          ? invoicesData?.[0]?.hasOwnProperty("totalTds")
                            ? parseFloat(
                              invoicesData?.[0]?.totalTds < 0
                                ? invoicesData?.[0]?.totalTds * -1
                                : invoicesData?.[0]?.totalTds
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(0).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total Invoice Value
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {invoicesData?.length
                          ? invoicesData?.[0]?.hasOwnProperty(
                            "totalInvoiceAmount"
                          )
                            ? parseFloat(
                              invoicesData?.[0]?.totalInvoiceAmount < 0
                                ? invoicesData?.[0]?.totalInvoiceAmount * -1
                                : invoicesData?.[0]?.totalInvoiceAmount
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(0).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total Taxable Value
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {invoicesData?.length
                          ? invoicesData?.[0]?.hasOwnProperty(
                            "totalTaxableValue"
                          )
                            ? parseFloat(
                              invoicesData?.[0]?.totalTaxableValue < 0
                                ? invoicesData?.[0]?.totalTaxableValue * -1
                                : invoicesData?.[0]?.totalTaxableValue
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(0).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total Other
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {invoicesData?.length
                          ? invoicesData?.[0]?.hasOwnProperty("totalOther")
                            ? parseFloat(
                              invoicesData?.[0]?.totalOther < 0
                                ? invoicesData?.[0]?.totalOther * -1
                                : invoicesData?.[0]?.totalOther
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(0).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}
            </Index.Box>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table invoice-table-set  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Head
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Barges Or Tugs
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      IGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      CGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      SGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      TDS
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Voucher Number
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable Value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Other
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Type
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Credit Note
                    </Index.TableCell>
                    {/* <Index.TableCell>Payment Status</Index.TableCell> */}
                    <Index.TableCell>Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={15}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="invoice-table-body">
                    {invoicesData?.length ? (
                      invoicesData?.map((row, index) => (
                        <Index.TableRow key={row?.guid + (index + 1)}
                          className={
                            row?.isCreditNote === 1 || row?.isCreditNote === "1"
                              ? "invoice-row-color"
                              : ""
                          }
                        >
                          <Index.TableCell>
                            {row?.date
                              ? Index.moment(row?.date).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>{" "}
                          <Index.TableCell>
                            {row?.party_name ? row?.party_name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.expense_head ? row?.expense_head : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.cost_center ? row?.cost_center : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.igst
                              ? row?.igst >= 0
                                ? row?.igst
                                : (row?.igst * -1)?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.cgst
                              ? row?.cgst >= 0
                                ? row?.cgst
                                : (row?.cgst * -1)?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.sgst
                              ? row?.sgst >= 0
                                ? row?.sgst
                                : (row?.sgst * -1)?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.tds
                              ? row?.tds >= 0
                                ? row?.tds
                                : (row?.tds * -1)?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.voucher_number ? row?.voucher_number : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoice_amount
                              ? row?.invoice_amount >= 0
                                ? row?.invoice_amount
                                : (row?.invoice_amount * -1)?.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.taxable_value
                              ? row?.taxable_value >= 0
                                ? row?.taxable_value
                                : (row?.taxable_value * -1)?.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.other
                              ? row?.other >= 0
                                ? row?.other
                                : (row?.other * -1)?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.barge_expense_type
                              ? row?.barge_expense_type
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.isCreditNote === 0 ||
                              row?.isCreditNote === "0"
                              ? "False" :
                              row?.isCreditNote === 1 ||
                                row?.isCreditNote === "1"
                                ? "True" : "-"}
                          </Index.TableCell>
                          {/* <Index.TableCell>
                            <Index.Switch
                              {...label}
                              checked={row?.isPaid}
                              onClick={() => handlePaymentstatus(row?._id)}
                              color="success"
                              size="small"
                            />
                          </Index.TableCell> */}
                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.VisibilitySharpIcon
                                onClick={() => {
                                  // navigate(
                                  //   `/admin/tallyInvoice/details/${row?.guid}`,
                                  //   {
                                  //     state: row,
                                  //   },
                                  // );
                                  const value = createSearchParams({
                                    guid: row?.guid,
                                    companyName: row?.company_name,
                                    partyName: row?.party_name,
                                    date: row?.date,
                                    voucherName: row?.voucher_number,
                                  });
                                  window.open(
                                    `/admin/tallyInvoice/details/${row?.guid}?${value} `,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={19}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default TallyInvoiceComponent;
