import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import { NEW_EXPORT_EXCEL } from "../../ExportAll";
import { getBanksTransactions } from "../../../../../redux/admin/action";
import { useSearchParams } from "react-router-dom";

function BankTransaction() {
  const dispatch = Index.useDispatch();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  const handleGetTransactionsDetail = (values) => {
    setLoading(true);
    dispatch(
      getBanksTransactions({
        ...values,
        companyName: searchParams.get("companyName"),
        ledgerId: searchParams.get("ledgerId"),
      })
    )
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setTotalRecordCount(res?.totalCount);
          setCurrentPage(res?.page);
          setTransactionData(res?.data);
        } else {
          setLoading(false);
          setTotalRecordCount(0);
          setCurrentPage(1);
          setTransactionData([]);
        }
      })
      .catch((error) => console.error(error));
  };

  //   when the page first time load then used...
  useEffect(() => {
    const values = {
      page: currentPage,
      size: paginationPerPage,
    };
    handleGetTransactionsDetail(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        // search: searchingText,
        size: paginationPerPage,
        // companyName: selectedCompany,
        // to:
        //   serachedDateRange?.length > 0 && serachedDateRange[0] !== null
        //     ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0 && serachedDateRange[1] !== null
        //     ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
        //     : "",
      };
      handleGetTransactionsDetail(values);
    },
    [paginationPerPage, setCurrentPage]
  );

  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      size: value,
      //   search: searchingText,
      //   companyName: selectedCompany,
      //   to:
      //     serachedDateRange?.length > 0 && serachedDateRange[0] !== null
      //       ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
      //       : "",
      //   from:
      //     serachedDateRange?.length > 0 && serachedDateRange[1] !== null
      //       ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
      //       : "",
    };
    handleGetTransactionsDetail(values);
  };

  return (
    <>
      <Index.DynamicTitle title="Bank Transactions" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bank Transactions
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["year", "month"]}
                        minDate={dayjs("2024-01-01")}
                        maxDate={dayjs()}
                        value={selectedDate}
                        onChange={(newValue) => handleChangeDate(newValue)}
                        renderInput={(params) => (
                          <Index.TextField {...params} helperText={null} />
                        )}
                      />
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box> */}
                <Index.Box></Index.Box>
                {/* <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "YYYY-MM-DD"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/new-invoice-export",
                        `Tally_Invoice_List_${curDate}`,
                        {
                          //   search: searchingText,
                          //   to:
                          //     serachedDateRange?.length > 0
                          //       ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
                          //       : "",
                          //   from:
                          //     serachedDateRange?.length > 0
                          //       ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
                          //       : "",
                          //   companyName: selectedCompany,
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table invoice-table-set  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                     Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Amount
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                     Voucher Type
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                     Party Name
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={15}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="invoice-table-body">
                    {transactionData?.length ? (
                      transactionData?.map((row, index) => (
                        <Index.TableRow
                          key={row?.guid + (index + 1)}
                          className={
                            row?.isCreditNote === 1 || row?.isCreditNote === "1"
                              ? "invoice-row-color"
                              : ""
                          }
                        >
                          <Index.TableCell>
                            {row?.date ? Index.moment(row?.date).format("YYYY-MM-DD")  : "-"}
                          </Index.TableCell>{" "}
                          <Index.TableCell>
                            {row?.amount ? row?.amount : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.voucher_type ? row?.voucher_type : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.party_name ? row?.party_name : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={19}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default BankTransaction;
