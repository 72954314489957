import type from "./type";
import APIService from "../APIService";
import axios from "../../axios";
import { toast } from "react-toastify";
import { webRoleMaster } from "../../config/CommonRoleMaster";
// import { Navigate } from "react-router-dom";

// Login
export const login = (data, navigate, setLoading) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin-login", data);
    if (response?.data?.status === 200) {
      // Dispactch reducer action
      toast.success(response.data.message);
      dispatch({
        type: type.ADMIN_LOGIN_SUCCESS,
        payload: response?.data?.data?.admin,
      });
      const token = response.data.data.token;
      // Set token to localstorage
      localStorage.setItem("accessToken", token);
      // Set access token in axios instance
      axios.defaults.headers.common.auth = token;
      setLoading(false);
      // when the company selection basis then used...
      // navigate("/admin/selectcompany");

      // Here is by default select company is BSJPL
      dispatch(
        adminSelectCompany({
          _id: "6655c2cb793fa6e4f4ac4e93",
          name: "BSJPL",
          fullName: "BSJPL",
          tallyCompanyName: "Ms. Bapus Shipping Jamnagar Pvt. Ltd. (15-18)",
        })
      );

      // ---------------- HERE IS REMOVED COMPANY SELECTION BASED CODE ----------------------------------
      const roleName = response?.data?.data?.admin?.permission?.roleName;
      const permission = response?.data?.data?.admin?.permission?.webPermission;

      const webrolemasterTags = webRoleMaster.map((item) => item.tag);
      const webrolemasterChild = webRoleMaster.filter((item) => item.isChild);

      const sortedPermission = {};
      webrolemasterTags.forEach((tag) => {
        if (permission[tag] !== undefined) {
          sortedPermission[tag] = permission[tag];
        }
      });

      webrolemasterChild.map((tag) => {
        // For child webRole master conditions
        tag.child.forEach((item) => {
          if (permission[item?.tag] !== undefined) {
            sortedPermission[item?.tag] = permission[item?.tag];
          }
        });
      });

      let firstMenu = null;
      for (const key in sortedPermission) {
        if (sortedPermission[key] === true) {
          firstMenu = key;
          break; // Stop the loop when the first true value is found
        }
      }

      if (roleName === "Super Admin" || roleName === "Finance") {
        navigate(`/admin/companyDashboard`);
      } else {
        navigate(`/admin/${firstMenu}`);
      }
    } else {
      toast.error(response.response.data.message);
      // setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 5500);
    }
  } catch (err) {
    toast.error(err.response.data.message);
    // setLoading(false)
    setTimeout(() => {
      setLoading(false);
    }, 5500);
  }
};

// change Password
export const changePassword = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/change-password", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    navigate("/admin/changepassword");
  } else {
    toast.error(response.response.data.message);
  }
};

// forgot Password
export const forgotPassword =
  (data, navigate, setLoading) => async (dispatch) => {
    try {
      const response = await APIService.doPost("/admin/forgot-password", data);
      if (response?.data?.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
        navigate("/");
      } else {
        toast.error(response.response.data.message);
        setTimeout(() => {
          setLoading(false);
        }, 5500);
      }
    } catch (err) {
      toast.error(err.response.data.message);
      setTimeout(() => {
        setLoading(false);
      }, 5500);
    }
  };

// reset Password
export const resetPassword = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/reset-password", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    navigate("/");
  } else {
    toast.error(response.response.data.message);
  }
};

// add barge
export const addBarge = (data) => async (dispatch) => {
  const response = await APIService.doPost("/add-edit-barge", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// get single barge(Craft) by id
export const getSingleBarge = (data) => async (dispatch) => {
  const response = await APIService.doPost("/getSingleBarge", data);
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// list barge
export const getBargeList = () => async (dispatch) => {
  // const response = await APIService.doGet("/getBargeList");
  const response = await APIService.doGet("/getBargeList");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.BARGE_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// getbarge category wise
export const getBargeListByCategory = (id, navigate) => async (dispatch) => {
  const response = id
    ? await APIService.doGetQueryParams("/admin/getBargeListByCategory", {
        params: { portId: id },
      })
    : await APIService.doGetQueryParams("/admin/getBargeListByCategory");

  return response?.data;
};

// delete barge
export const deleteBarge = (data, handleDeleteClose) => async (dispatch) => {
  const response = await APIService.doPost("/delete-barge", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    handleDeleteClose();
    // Dispactch reducer action
    dispatch(getBargeList());
  } else {
    toast.error(response.response.data.message);
  }
};

// get users name list

export const getAllAssigneeUsersList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/listOfEmployeeName");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};
// assign user into barge

export const assignUserIntoBarge = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/assign-user-into-barge",
    data
  );
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// list port
export const getPortList = () => async (dispatch) => {
  const response = await APIService.doGet("/getPortList");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.PORT_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// list port wihout auth
export const getTraderPortList = () => async (dispatch) => {
  const response = await APIService.doGet("/getPortListForTrader");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.PORT_LIST_TRADER_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  } else {
    // toast.error(response.response.data.message);
  }
};

// list port wihout auth
export const getSalesTraderList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/trader-list-for-sales");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.SALES_TRADER_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  } else {
    // toast.error(response.response.data.message);
  }
};

// add port
export const addPort = (data, navigate, setLoading) => async (dispatch) => {
  const response = await APIService.doPost("/add-edit-port", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    setLoading(false);
    navigate("/admin/port");
  } else {
    toast.error(response.response.data.message);
    setTimeout(() => {
      setLoading(false);
    }, 5500);
  }
};

// delete port
export const deletePort = (data, handleDeleteClose) => async (dispatch) => {
  const response = await APIService.doPost("/delete-port", data);
  if (response?.data?.status === 200) {
    handleDeleteClose();
    toast.success(response.data.message);
    // Dispactch reducer action
    dispatch(getPortList());
  } else {
    toast.error(response.response.data.message);
  }
};

// addorder
export const addOrder = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/add-edit-order", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getOrder());
    // navigate("/admin/order");
    return response?.data;
  }
  // else {
  //   toast.error(response.response.data.message);
  // }
};

// export const add and edit order then used...
export const addEditOrder = (values) => async () => {
  const response = await APIService.doPost("/add-edit-order", values);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// getOrder
export const getOrder = (values) => async (dispatch) => {
  const response = await APIService.doPost("/admin/current-order-list", values);
  if (response?.data?.status === 200) {
    // dispatch({
    //   type: type.GET_ORDER_LIST_SUCCESS,
    //   payload: response.data.data,
    // });
    return response?.data;
  }
};

// get Cancel Order
export const getCancelOrder = (values) => async (dispatch) => {
  const response = await APIService.doPost("/admin/cancel-order-list", values);
  if (response?.data?.status === 200) {
    // dispatch({
    //   type: type.GET_CONFIRM_ORDER_LIST_SUCCESS,
    //   payload: response.data.data,
    // });
    return response?.data;
  }
};

// Update Order
export const updateOrderStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/update-status", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getOrder());
  } else {
    toast.error(response.data.message);
  }
};

// deleteOrder
export const deleteOrder = (id, handleDeleteClose) => async (dispatch) => {
  const response = await APIService.doPost("/delete-order", id);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    handleDeleteClose();
    dispatch(getOrder());
  } else {
    toast.error(response.data.message);
  }
};

//admin Logout
export const adminLogout = (data) => async (dispatch) => {
  localStorage.removeItem("accessToken");
  dispatch({
    type: type.ADMIN_LOGOUT,
    payload: "",
  });
  dispatch({
    type: type.GET_LOCATION,
  });
};

// when the user logout then used...
export const userLogout = () => async (dispatch) => {
  const response = await APIService.doGet("/admin-logout");
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    localStorage.removeItem("accessToken");
    dispatch({
      type: type.ADMIN_LOGOUT,
      payload: "",
    });
    dispatch({
      type: type.GET_LOCATION,
    });
    // dispatch(getInvoice());
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// update admin  profile

export const updateAdminProfile = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/update-profile", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    dispatch({
      type: type.ADMIN_UPDATE_PROFILE,
      payload: response.data.data,
    });
  }
};

// invoice api
// getinvoice
export const getInvoice = (setLoading) => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-all-invoice");
  setLoading(false);
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_INVOICE_LIST_SUCCESS,
      payload: response.data.data,
    });
  }
};

// here is new api for get the invoice list
export const getInvoices = (values) => async (dispatch) => {
  const response = await APIService.doPost("/admin/list-of-invoice", values);
  return response.data;
};
export const getTallyInvoices = (values) => async (dispatch) => {
  // const response = await APIService.doPost("/admin/get-tally-invoice", values);
  const response = await APIService.doPost("/admin/new-invoice", values);
  return response.data;
};

// getinvoice credit not
export const getCreditNotInvoice = (setLoading) => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-credit-note-invoice");
  setLoading(false);
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_CREDIT_NOT_INVOICE,
      payload: response.data.data,
    });
  }
  return response?.data;
};

// here new api for getInvoice for credite notes basis
export const getCreditNoteInvoice = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/credit-note-invoice",
    values
  );
  return response?.data;
};

//get single invoice
export const getInvoiceActivityLog = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-activity-log", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    dispatch({
      type: type.GET_INVOICE_ACTIVITY_LOG,
      payload: response.data.data,
    });
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// add invoice
export const addInvoice = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-invoice", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getInvoice());
    navigate("/admin/invoice");
  } else {
    toast.error(response.response.data.message);
  }
};

// Paid UnPaid Payment Status
export const AddPaymentStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/update-paid-unpaid", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getInvoice());
    return response;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// delete invoice
export const deleteInvoice = (data, handleDeleteClose) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-invoice", data);
  if (response?.data?.status === 200) {
    handleDeleteClose();
    toast.success(response?.data?.message);
    // Dispactch reducer action
    dispatch(getInvoice());
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//get single invoice
export const getSingleInvoice = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-invoice", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//get single invoice
export const getParticulars = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/getall-particular");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_PARTICULAR_LIST,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// add particular
export const addParticular = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-particular", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    dispatch(getParticulars());
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// delete particular
export const deleteParticular = (rowId) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-particular", {
    id: rowId,
  });
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getParticulars());
  } else {
    toast.error(response.response.data.message);
  }
};

//dash board Api's
// get toatl sales dashboard

export const getTotalSalseDashboard = (data) => async (dispatch) => {
  const response = await APIService.doGet(
    `/admin/total-sale?companyName=${data}`
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_TOTAL_SALSE_DASHBOARD,
      payload: response.data,
    });
  } else {
    dispatch({
      type: type.GET_TOTAL_SALSE_DASHBOARD,
      payload: 0,
    });
  }
};

// when the user change the purchase type then used....
export const changePurchaseDirectIndirectType =
  (values) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/update-expense-type",
      values
    );
    if (response?.data?.status === 200) {
      toast.success(response.data.message);
      return response?.data;
    } else {
      toast.error(response.response.data.message);
    }
  };

// when the user change the wharfage type then used...
export const changeWharfageActionType = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/update-wharfage-order",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// invoice details dashboard for total and paid and unpaid invoice
export const getInvoiceDetailDashboard = (data) => async (dispatch) => {
  const response = await APIService.doGet(
    `${`/admin/invoice-bunker-details/`}${data}`
  );
  if (response?.data?.data) {
    dispatch({
      type: type.GET_INVOICE_DETAILS_DASHBOARD,
      payload: response.data.data,
    });
  }
};

// // invoice total  dashboard only total invoice
// export const getInvoiceTotalDetailByOverDue = (data) => async (dispatch) => {
//   const response = await APIService.doGetQueryParams(
//     "/admin/invoice-bunker-details-by-overdue",
//     {
//       params: { days: data },
//     }
//   );
// if (response?.status === 200) {
//   return response?.data;
// }

// };

export const getInvoiceTotalDetailByOverDue = () => async (dispatch) => {
  const response = await APIService.doGet(
    "/admin/invoice-bunker-details-by-overdue"
  );
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// when the user get the due invoice counts then used
export const getInvoiceTotalDetailByOverDuev2 =
  (values) => async (dispatch) => {
    const response = await APIService.doGet(
      `/v2/admin/tally-invoice-bunker-details-by-overdue?companyName=${values}`
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  };

// category details dashboard
export const getCategoryDetail = (data) => async (dispatch) => {
  const response = await APIService.doGet(`${`admin/category-wise/`}${data}`);
  if (response?.data?.data) {
    dispatch({
      type: type.GET_CATEGORY_WISE_DASHBOARD,
      payload: response.data.data,
    });
  }
};

// get Sales Party wise
export const getSalsePartyWises = (data) => async (dispatch) => {
  const response = await APIService.doGet(
    `${`admin/sales-party-wise/`}${data}`
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_SALSE_PARTY_WISE,
      payload: response.data.data,
    });
  }
};

// purchase sheet upload api

export const addPurchase = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/total-purchase", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getPurchaseList());
    // navigate("/admin/invoice");
  } else {
    toast.error(response.response.data.message);
  }
};

//get purchase list  api
export const getPurchaseList = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-all-configue-purchase-data",
    values
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_PURCHASE_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  }
};

// when the get all the wharfage records then used...
export const getWharfageList = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-wharfage-order-list",
    values
  );
  if (response?.data?.status === 200) {
    // dispatch({
    //   type: type.GET_PURCHASE_LIST_SUCCESS,
    //   payload: response.data.data,
    // });
    return response?.data;
  }
};

export const purchaseExcelData = (values) => async (dispatch) => {
  // get-all-purchase-data

  const response = await APIService.doPost(
    "/admin/get-all-purchase-data",
    values
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_PURCHASE_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  }
};

// delete purchase
export const deletePurchase = (data, handleDeleteClose) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-purchase", data);
  if (response?.data?.status === 200) {
    handleDeleteClose();
    toast.success(response.data.message);
    // Dispactch reducer action
    dispatch(getPurchaseList());
  } else {
    toast.error(response.data.message);
  }
};

// when the user get the purchase invoice details then used...
export const getPuchaseInvoiceDetails = (values) => async (dispatch) => {
  // const response = await APIService.doGet(
  //   `/admin/barge-purchase/?${values}`,
  //   values
  // );
  const response = await APIService.doPost(`/v2/admin/barge-purchase`, values);
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// when the user get the purchase invoice side journal data then used...
export const getJournalInvoiceDetails = (values) => async (dispatch) => {
  // const response = await APIService.doGet(
  //   `/admin/barge-purchase/?${values}`,
  //   values
  // );
  const response = await APIService.doPost(`/v2/admin/barge-journal`, values);
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// when the user get the sales invoice details then used...
export const getSalesInvoiceDetails = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    `/v2/admin/barge-wise-sales-list`,
    values
  );
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// when the get the due invoice details then used...
export const getDueInvoiceDetailsByDateWise = (values) => async (dispatch) => {
  // const response = await APIService.doGet(
  //   `/admin/barge-purchase/?${values}`,
  //   values
  // );
  const response = await APIService.doPost(
    `/admin/barge-wise-sales-for-invoice`,
    values
  );
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// when the user show category wise all the invoice or recores then used..
export const getCategoryWiseAllInvoices = (values) => async (dispatch) => {
  const response = await APIService.doPost(`admin/category-wise-list`, values);
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// when the user show tops sales party wise all the invoice or recores then used..
export const getTopSalesPartyWiseInvoices = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    `/admin/top-ten-sales-party`,
    values
  );
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// when the user show tops sales party tally wise all the invoice or recores then used..
export const getTopSalesPartyTallyWiseInvoices =
  (values) => async (dispatch) => {
    const response = await APIService.doPost(
      `/admin/tally-top-ten-sales-party`,
      values
    );

    return response?.data;
  };

// add HDFC  Bank  sheet upload api

export const addHDFCBankSheet = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/hdfc-sheet", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getHDFCBankList());
    // navigate("/admin/invoice");
  } else {
    toast.error(response.response.data.message);
  }
};

//get HDFC bank  list  api
export const getHDFCBankList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-hdfc-data");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_HDFC_BANK_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  }
};

// delete HDFC bank list
export const deleteHDFCBankSheet =
  (data, handleDeleteClose) => async (dispatch) => {
    const response = await APIService.doPost("/admin/delete-hdfc", data);
    if (response?.data?.status === 200) {
      handleDeleteClose();
      toast.success(response.data.message);
      dispatch(getHDFCBankList());
    } else {
      toast.error(response.data.message);
    }
  };

// add Kotak  Bank  sheet upload api

export const addKotakBanksheet = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/kotak-sheet", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getKotakBankList());
    // navigate("/admin/invoice");
  } else {
    toast.error(response.response.data.message);
  }
};

//get kotak bank  list  api
export const getKotakBankList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-kotak-data");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_KOTAK_BANK_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  }
};

// delete kotak bank list
export const deleteKotakBankSheet =
  (data, handleDeleteClose) => async (dispatch) => {
    const response = await APIService.doPost("/admin/delete-kotak", data);
    if (response?.data?.status === 200) {
      handleDeleteClose();
      toast.success(response.data.message);
      dispatch(getKotakBankList());
    } else {
      toast.error(response.data.message);
    }
  };

// add Bank Remark
export const addBankRemark = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/edit-remark", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// journal sheet upload api

export const addJournalSheet = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/journal-sheet", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getJournalList());
    // navigate("/admin/invoice");
  } else {
    toast.error(response.response.data.message);
  }
};

//get journal list  api
export const getJournalList = (values) => async (dispatch) => {
  // const response = await APIService.doGet("/admin/getall-journal");
  const response = await APIService.doPost(
    "/admin/get-all-configue-journal-data",
    values
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_JOURNAL_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  }
};

// delete journal list
export const deleteJournalList =
  (data, handleDeleteClose) => async (dispatch) => {
    const response = await APIService.doPost("/admin/delete-journal", data);
    if (response?.data?.status === 200) {
      handleDeleteClose();
      toast.success(response.data.message);
      // Dispactch reducer action
      dispatch(getJournalList());
    } else {
      toast.error(response.data.message);
    }
  };

// add Party Name
export const AddPartyName = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-party", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);

    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

//get paty
export const getPartyName = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/getPartyList");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_PARTYLIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  }
};

// delete party
export const deletePartyName = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-party", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getPartyName());
  } else {
    toast.error(response.response.data.message);
  }
};

// delete admin enquiry
export const deleteEnquiry = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-inquiry", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getInquiryList());
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// delete admin enquiry
export const deleteTraderEnquiry = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/delete-inquiry-by-trader",
    data
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// Total HDFC details dashboard
export const getTotalHdfcSales = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/total-hdfc-sales");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_HDFC_TOTAL_DASHBOARD,
      payload: response.data.data,
    });
  }
};

// Total Kotak details dashboard
export const getTotalKotakSales = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/total-kotak-sales");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_KOTAK_TOTAL_DASHBOARD,
      payload: response?.data?.data,
    });
    return response;
  }
};

// Invoice File upload api
export const addInvoiceFile = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/upload-invoice", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// Invoice send mail
export const InvoiceSendMail = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/send-invoice-mail", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
  } else {
    toast.error(response.response.data.message);
  }
};

// for delete sheet

// delete purchase total sheet
export const deletePurchaseSheet = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-purchase-data", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    dispatch(getPurchaseList());
  } else {
    toast.error(response.data.message);
  }
};

// delete purchase total sheet
export const deleteJournalSheet =
  (data, handleDeleteClose) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/delete-purchase-data",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response.data.message);
      dispatch(getJournalList());
    } else {
      toast.error(response.data.message);
    }
  };

// delete hdfc all sheet
export const deleteHDFCSheet =
  (data, handleDeleteClose) => async (dispatch) => {
    const response = await APIService.doPost("/admin/delete-bank-data", data);
    if (response?.data?.status === 200) {
      toast.success(response.data.message);
      dispatch(getHDFCBankList());
    } else {
      toast.error(response.data.message);
    }
  };

// delete kotak all sheet
export const deleteKotakSheet =
  (data, handleDeleteClose) => async (dispatch) => {
    const response = await APIService.doPost("/admin/delete-bank-data", data);
    if (response?.data?.status === 200) {
      toast.success(response.data.message);
      dispatch(getKotakBankList());
    } else {
      toast.error(response.data.message);
    }
  };

// update inquiry
export const updateInquiryStatus = (data, handleClose) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/update-inquiry-status",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    handleClose();
    dispatch(getInquiryList());
  } else {
    toast.error(response.data.message);
  }
};

// update Trader InquiryStatus;
export const updateTraderInquiryStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/update-enquiry-confirm-by-trader",
    data
  );
  if (response?.status === 200) {
    toast.success(response.data.message);
  } else {
    toast.error(response.data.message);
  }
  return response?.data;
};

// when the trader confirm the only one trader instead of multiple trader
export const updateAndConfirmOneTraderEnquiryStatus =
  (data) => async (dispatch) => {
    const response = await APIService.doPost(
      "admin/update-inquiry-status-for-traders-admin",
      data
    );
    if (response?.status === 200) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    return response?.data;
  };

// update sales InquiryStatus;
export const updateSalesInquiryStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/update-inquiry-status-by-sales",
    data
  );
  if (response?.status === 200) {
    toast.success(response.data.message);
  } else {
    toast.error(response.data.message);
  }
  return response?.data;
};

// put back  inquiry
export const lostEnquiryPutBack = (data, handleClose) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/update-multiple-lost-inquiry-status",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    handleClose();
    dispatch(getLostEnquiry());
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// update multiple enquiry
export const updateMultipleInquiryStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/update-multiple-inquiry-status",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getInquiryList());
    return response?.data;
  } else {
    toast.error(response?.data?.message);
  }
};

// update multiple order to complete

export const updateMarkAsCompleteOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/mark-as-completed-order",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getOrder());
    return response?.data?.status;
  } else {
    toast.error(response?.data?.message);
  }
};

// get inquiry
export const getInquiryList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-Inquiry-List ");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_INQUIRY_lIST_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// get enquiries with pagination basis
export const getEnquiresList = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-current-enquiries-list",
    values
  );
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// get Trader's enquiry listing
export const getTraderEnquiryList = (values) => async (dispatch) => {
  try {
    const traderListResponse = await APIService.doGet(
      "/admin/get-Inquiry-List-for-trader"
    );
    if (traderListResponse?.data?.status === 200) {
      return dispatch({
        type: type.GET_TRADER_INQUIRY_lIST_SUCCESS,
        payload: traderListResponse?.data?.data,
      });
    } else {
      return dispatch({
        type: type.GET_TRADER_INQUIRY_lIST_SUCCESS,
        payload: [],
      });
    }
  } catch (err) {
    console.error("get trader listing time error", err);
  }
};

// Trader's Enquiry with pagination (ENQUIRY DATE CHECKING)
export const getTraderEnquiriesList = (values) => async () => {
  try {
    const response = await APIService.doPost(
      "/admin/get-enquiries-list-for-traders",
      values
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (err) {
    console.error("get trader listing time error", err);
  }
};

// here get trader's enquiry id basis
export const getTraderEnquiryListById = (values) => async (dispatch) => {
  try {
    const traderListResponse = await APIService.doGet(
      `/admin/get-current-Inquiry-List-for-trader/${values}`
    );
    if (traderListResponse?.data?.status === 200) {
      return dispatch({
        type: type.GET_TRADER_INQUIRY_lIST_BY_ID_SUCCESS,
        payload: traderListResponse?.data?.data,
      });
    } else {
      return dispatch({
        type: type.GET_TRADER_INQUIRY_lIST_BY_ID_SUCCESS,
        payload: [],
      });
    }
  } catch (err) {
    console.error("get trader listing time error", err);
  }
};

// get trader's lost enquiry list
export const getTradersLostEnquiryList = () => async (dispatch) => {
  try {
    const traderLostListResponse = await APIService.doGet(
      "/admin/get-lost-list-for-trader"
    );
    if (traderLostListResponse?.data?.status === 200) {
      return dispatch({
        type: type.GET_TRADER_LOST_INQUIRY_lIST_SUCCESS,
        payload: traderLostListResponse?.data?.data,
      });
    } else {
      return dispatch({
        type: type.GET_TRADER_LOST_INQUIRY_lIST_SUCCESS,
        payload: [],
      });
    }
  } catch (err) {
    console.error("get trader listing time error", err);
  }
};

// here is showing trader's confirm enquiry list
export const getTraderConfirmEnquiryList = (values) => async (dispatch) => {
  try {
    const traderConfirmListResponse = await APIService.doGet(
      `/admin/get-confirm-inquiry-list-for-trader/${values}`
    );
    if (traderConfirmListResponse?.data?.status === 200) {
      return dispatch({
        type: type.GET_TRADER_CONFIRM_INQUIRY_lIST_SUCCESS,
        payload: traderConfirmListResponse?.data?.data,
      });
    } else {
      return dispatch({
        type: type.GET_TRADER_CONFIRM_INQUIRY_lIST_SUCCESS,
        payload: [],
      });
    }
  } catch (err) {
    console.error("get trader listing time error", err);
  }
};

// get trader  Enquiry list
export const getTraderInquiryList = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/get-inquiry-by-single-trader",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// add confirmation for enquiry basis
export const confirmationOfTraderEnquiry = (data) => async (dispatch) => {
  try {
    const confirmationResponse = await APIService.doPost(
      "admin/update-date-of-Inquiry-trader",
      data
    );
    if (
      confirmationResponse?.data?.status === 200 ||
      confirmationResponse?.data?.status === 201
    ) {
      toast.success(confirmationResponse.data.message);
      return confirmationResponse?.data;
    } else {
      return confirmationResponse;
    }
    //  else {
    //   toast.error(confirmationResponse?.response?.data?.message);
    // }
  } catch (err) {
    console.log("Trader's enquiry confirmation time error", err);
  }
};

// when the user add the EDD then used..
export const addEDDOfTraderEnquriy = (data) => async (dispatch) => {
  try {
    const addEddTraderResponse = await APIService.doPost(
      "admin/update-date-of-Inquiry-trader",
      data
    );
    if (
      addEddTraderResponse?.data?.status === 200 ||
      addEddTraderResponse?.data?.status === 201
    ) {
      toast.success(addEddTraderResponse?.data?.message);
      return addEddTraderResponse?.data;
    } else {
      return addEddTraderResponse;
    }
    //  else {
    //   toast.error(confirmationResponse?.response?.data?.message);
    // }
  } catch (err) {
    console.log("Trader's enquiry confirmation time error", err);
  }
};

// get trader  Enquiry list
export const getSalesInquiryList = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/get-inquiry-by-single-sales",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};
// get lost inquiry
export const getLostEnquiry = () => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-lost-list");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_LOST_ENQUIRY_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// get lost enquiries list for lost enquiries
export const getLostEnquiriesList = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/get-lost-enquiries-list",
    values
  );
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// add inquiry
export const addInquiry = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-Inquiry", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getInquiryList());
    navigate(`/admin/enquiry`);
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//edit bunker timing
export const addEditBunkerTiming = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/upload-challan", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getInquiryList());
    // navigate(`/admin/inquiry`);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// add trader inquiry
export const addTraderEnquiry = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/add-edit-inquiry-by-trader",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getTraderInquiryList());
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// add trader inquiry
export const addSalesEnquiry = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/add-edit-inquiry-by-sales",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//get single order
export const getSingleOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost("/getSingleOrder", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
  } else {
    toast.error(response?.response?.data?.message);
  }
  return response?.data;
};

// Order PDF File upload api
export const addOrderPdfFile = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/upload-wharfage-file", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// Order PDF File upload api
export const addOrderProformaPdfFile = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/proforma-pdf-upload", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

//get challan list
export const getChallanList = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/getChallanList", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//post challan status
export const addChallanStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/approve-reject-challan",
    data
  );
  if (response?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//post challan request status
export const addChallanEditReqStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/approve-reject-challan-edit-request",
    data
  );
  if (response?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// add user
export const addUser = (data, navigate, setLoading) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-user", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getUserList());
    setLoading(false);
    navigate(`/admin/users`);
  } else {
    toast.error(response?.response?.data?.message);
    setTimeout(() => {
      setLoading(false);
    }, 5500);
  }
};

// get inquiry
export const getUserList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-user-list");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_USER_LIST_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// delete user
export const deleteUser = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-user", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getUserList());
  } else {
    toast.error(response.response.data.message);
  }
};

//get single user
export const getSingleUser = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-single-user", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//post cancel  order( nominator )
export const cancelOrder = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/confirm-to-cancel", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    navigate("/admin/order");
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//all cancel order
export const allCancelOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/multiple-confirm-to-cancel",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    return response?.data?.status;
  } else {
    toast.success(response?.data?.message);
  }
};

// checkbox api for shippingbill warfall or harbour
export const orderShippingBillBoxCategory =
  (data, getOrderList) => async (dispatch) => {
    const response = await APIService.doPost("/admin/update-check-box", data);
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response?.data?.message);
      dispatch(getOrderList());
    } else {
      toast.success(response?.data?.message);
    }
  };

// Completed Order list in current order
export const completedOrderList = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/completed-order-list",
    values
  );
  if (response?.data?.status === 200) {
    // dispatch({
    //   type: type.GET_COMPLETED_ORDER_LIST_SUCCESS,
    //   payload: response.data.data,
    // });
    return response?.data;
  }
};

// get bargeExpenseList
export const getBargeExpenseList = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-barge-expense", data);
  return response?.data;
};

//get single barge expense
export const getSingleBargeExpense = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-single-barge-expense",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get other barge expense
export const getOtherExpenseList = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-otherExpense", data);
  return response?.data;
};

//post Approve/reject  bargeExpenseList
export const approveRejectBargeExpense = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/approve-reject-bargeExpense",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    // dispatch(getBargeExpenseList());
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get direct and indirect purchase list then used..
export const getdirectAndIndirectPurchaseList = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/direct-indirect-purchase-list",
    values
  );
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get expense
export const getExpenseList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-expense-list");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_EXPENSE_LIST_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// delete expense
export const deleteExpense = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-expense", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getExpenseList());
  } else {
    toast.error(response.response.data.message);
  }
};

// add expense
export const addExpense = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-expense", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getExpenseList());
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// Paid UnPaid expense Status
export const updateExpenseStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/acive-deactive-expense",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getExpenseList());
    return response;
  } else {
    toast.error(response.response.data.message);
  }
};

//get name of vessel
export const getNameOfVessel = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/getVesselName");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_NAME_OF_VESSEL_LIST_SUCCESS,
      payload: response?.data?.data,
    });
  }
};

//get name of vessel for trader
export const getNameOfVesselForTrader = () => async (dispatch) => {
  const response = await APIService.doGet("admin/get-vessel-name-for-trader");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// challan iseditable
export const addChallanISEditable = (data) => async (dispatch) => {
  const response = await APIService.doPost("admin/is-editable-challan", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    return response?.data?.status;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// expense list  isEditable
export const addExpenseIsEditable = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/is-editable-barge-expense",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    return response?.data?.status;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// expense pdf
// export const generateExpensePdf = (data) => async (dispatch) => {
//   const response = await APIService.doPost("/admin/generate-pdf", data);
//   if (response?.data?.status === 200 || response?.data?.status === 201) {
//     toast.success(response?.data?.message);
//     return response?.data?.status;
//   } else {
//     toast.error(response?.response?.data?.message);
//   }
// };
//get single order
export const generateExpensePdf = (id, navigate) => async (dispatch) => {
  const response = await APIService.doGetParams("/admin/generate-pdf/", id);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
  } else {
    toast.error(response?.response?.data?.message);
  }
  return response?.data;
};

// get all expese request
// export const getAllBargeRequest = () => async (dispatch) => {
//   // const response = await APIService.doGet("/getBargeList");
//   const response = await APIService.doGet("/admin/get-edit-request-list");
//   if (response?.data?.status === 200) {
//     dispatch({
//       type: type.GET_EXPENSE_REQUEST_LIST_SUCCESS,
//       payload: response.data.data,
//     });
//   } else {
//     toast.error(response.response.data.message);
//   }
// };

//get single expense barge request
export const getSingleExpenseBargeRequest =
  (data, navigate) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/get-edit-request-list",
      data
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      return response?.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  };

//get Single Challan EditRequest
export const getSingleChallanEditRequest =
  (data, navigate) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/get-single-challan-edit-request",
      data
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      return response?.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  };

//approve rejct for expense request
export const expenseRequestApproveReject =
  (data, navigate) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/approve-reject-editRequest",
      data
    );
    if (response?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      return response?.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  };

// Add Trader Master
export const addTraderMaster =
  (data, navigate, setLoading) => async (dispatch) => {
    const response = await APIService.doPost("/admin/add-edit-trader", data);
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      dispatch(getTraderMasterList());
      setLoading(false);
      navigate(`/admin/traders`);
    } else {
      toast.error(response?.response?.data?.message);
      setTimeout(() => {
        setLoading(false);
      }, 5500);
    }
  };

// Add Tanker Master
export const addTankerMaster = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-tanker", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getTraderMasterList());
    navigate(`/admin/tankers`);
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get Trader Master
export const getTraderMasterList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-all-trader");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_TRADER_MASTER_LIST_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// delete Trader Master
export const deleteTraderMaster = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-trader", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getTraderMasterList());
  } else {
    toast.error(response.response.data.message);
  }
};

// get Tanker Master
export const getTankerMasterList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-all-tanker");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// get Single Tanker Master
export const getSingleTankerMaster = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-single-tanker", data);
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

//get Tanker Activity log
export const getTankerActivityLog = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-activity-log", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    dispatch({
      type: type.GET_TANKER_ACTIVITY_LOG,
      payload: response.data.data,
    });
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

export const getNotifications = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-notification");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_NOTIFICATION_LIST_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};
// delete Tanker Master
export const deleteTankerMaster = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-tanker", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// Add Role Master
export const addRoleMaster =
  (data, navigate, setLoading) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/add-edit-permission",
      data
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      dispatch(getRoleMasterList());
      setLoading(false);
      navigate(`/admin/roles`);
    } else {
      toast.error(response?.response?.data?.message);
      setTimeout(() => {
        setLoading(false);
      }, 5500);
    }
  };

// get Role Master
export const getRoleMasterList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-permission-list");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_ROLE_MASTER_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// delete Role Master
export const deleteRoleMaster = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-permission", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getRoleMasterList());
  } else {
    toast.error(response.response.data.message);
  }
};

// get employee
export const getEmployee = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/listAllDetails");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// get employee name list

export const getEmployeeNameList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/listOfEmployeeName");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};
// Add employee
export const addEmployee = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("admin/add-edit-employee", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getEmployee());
    navigate(`/admin/bunker`);
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// //token logout
// export const adminLogOutToken = (data, navigate) => async (dispatch) => {
//   const response = await APIService.doPost("/admin-logout", data);
//     return response;
// };

//get single user
export const getSingleEmployee = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-single-employee", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get Report gstr
export const getGSTR = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-Gstr-Report");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// get2 Report gstr
export const getGSTRTwo = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-gstr-data");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// gstr2B rate status update
export const gstrRateStatusUpdate = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/update-gstr-rate-and-status",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
  } else {
    toast.error(response.data.message);
  }
};

// get Report TDS
export const getTDS = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/getTdsData");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// admin Read Notification
export const adminReadNotification = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("admin/read-notification", data);
};

//get single Enquiry
export const getSingleEnquiry = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("admin/get-Single-Inquiry", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// Add Dashboard data
export const addDashboardData = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "admin/add-edit-dashboard-data",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getEmployee());
    // navigate(`/admin/bunker`);
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get dashboard data

export const getDashboardData = () => async (dispatch) => {
  const response = await APIService.doGet("admin/get-dashboard-data");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// add credit not invoice
export const putInCreditNotInvoice = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/put-invoice-in-credit-note",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get trader  Enquiry list
export const getSalesOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-bunker-order-list-for-sales",
    data
  );
  return response?.data;
};

// get Sales Master
export const getSalesMasterList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-all-sales-master");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_SALES_MASTER_LIST_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// Add Sales Master
export const addSalesMaster =
  (data, navigate, setLoading) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/add-edit-sales-master",
      data
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      dispatch(getSalesMasterList());
      setLoading(false);
      navigate(`/admin/salesMaster`);
    } else {
      toast.error(response?.response?.data?.message);
      setTimeout(() => {
        setLoading(false);
      }, 5500);
    }
  };

// delete Sales Master
export const deleteSalesMaster = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-sales-master", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getSalesMasterList());
  } else {
    toast.error(response.response.data.message);
  }
};

// order document upload api
export const addOrderDocument = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/upload-doc-file", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// delete order Document
export const deleteOrderDocument =
  (data, handleDeleteClose) => async (dispatch) => {
    const response = await APIService.doPost("/admin/delete-doc-file", data);
    if (response?.data?.status === 200) {
      toast.success(response.data.message);
      handleDeleteClose();
      return response?.data;
    } else {
      toast.error(response.response.data.message);
    }
  };

// here is used for wharfage and porforma invoice remove time
export const deleteCurrentOrderDocument = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-doc-file", data);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

//get Single Order Tanker Details
export const getLoadingOrderTankerDetailsRequest =
  (data) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/get-tanker-list-by-order",
      data
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      return response?.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  };

// Single Tanker doucment upload api
export const tankerDocumentUpload = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-tanker-documents", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// Get Single Tanker Documents api
export const getSingleTankerDocuments = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-all-tanker-documents",
    data
  );
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// delete tanker document by document id
export const deleteTankerDocumentById = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/delete-tanker-documents",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// get latest activity time api
export const getLatestActivityTime = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-latest-activity-time");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_LATEST_ACTIVITY_TIME_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// get latest activity time api
export const getLatestActivityInvoiceTime = () => async (dispatch) => {
  const response = await APIService.doGet(
    "/admin/get-latest-activity-time-invoice"
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_LATEST_INVOICE_ACTIVITY_TIME_SUCCESS,
      payload: response?.data?.data,
    });
    return response?.data;
  }
};

// get bunker order dashboard api
export const getBunkerOrderDashboardOrders = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-order-data-export",
    data
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_BUNKER_ORDER_DASHBOARD_SUCCESS,
      payload: response.data.data,
    });
    return response?.data.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// get all driver documents api
export const getAllDriversDocuments = (id) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/get-all-driver-documents",
      id
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};
// add driver documents api
export const addDriversDocument = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/add-driver-documents",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
// delete driver documents api
export const deleteDriversDocument = (id) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/delete-driver-documents",
      id
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

//Break Down Management
// get all tankers for breakdown api
export const getAllTankersForBreakdown = () => async (dispatch) => {
  try {
    const response = await APIService.doGet(
      "/admin/get-all-tanker-for-breakdown"
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// get all  breakdown api
export const getAllBreakdowns = () => async (dispatch) => {
  try {
    const response = await APIService.doGet("/admin/get-all-tanker-breakdown");
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
// add breakdown api
export const addBreakdown = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/add-tanker-breakdown",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// add breakdown revoke api
export const addBreakdownRevoke = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/revoke-tanker-breakdown",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

//Craft detail

//get all craft Crew list api
export const getAllCraftCrewList = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/get-all-crew-member",
      data
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};
//add edit craft Crew api
export const addEditCraftCrew = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/add-edit-crew-member",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
//delete craft Crew api here this is also used for sign off functionality basis
export const deleteCraftCrew = (id) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin/delete-crew-member", id);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

//Craft Document
//get all craft Documents list api
export const getAllCraftDocumentList = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/get-all-craft-documents",
      data
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};
//upload craft document api
export const addEditCraftDocument = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/add-craft-documents",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
//delete craft document api
export const deleteCraftDocument = (id) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/delete-craft-documents",
      id
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
//Craft Photos
//get all craft Photos list api
export const getAllCraftPhotoList = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/get-all-craft-photos",
      data
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};
//upload craft Photo api
export const addEditCraftPhoto = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost(
      "/admin/add-edit-craft-photos",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
//delete craft document api
export const deleteCraftPhoto = (id) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin/delete-craft-photos", id);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

//get activity logs of  craft detail page
export const getCraftDetailActivityLog =
  (data, navigate) => async (dispatch) => {
    const response = await APIService.doPost("/admin/get-activity-log", data);
    if (response?.data?.status === 200) {
      return response?.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  };

// get Single Tanker Trips List
export const getTankerTripsList = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-all-trips-of-tanker",
    data
  );
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// list same terminal Order
export const getSameTerminalOrder = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-order-of-same-terminal",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    return response?.data;
  } else {
    console.error(response?.response?.data?.message);
    // toast.error(response?.response?.data?.message);
  }
};

// Paid UnPaid Payment Status
export const assignTanker = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/shift-tanker", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getInvoice());
    return response;
  } else {
    toast.error(response?.response?.data?.message);
  }
};
//Craft maintenance

// get Craft maintenance List
export const getAllCraftMaintenanceList = (data) => async (dispatch) => {
  const response = await APIService.doGet(
    `/admin/get-maintenance?barge=${data?.id}&type=${data?.type}`
  );
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// add edit Craft maintenance List
export const addEditCraftMaintenance = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-maintenance", data);
  if (response?.data?.status === 201 || response?.data?.status === 200) {
    toast.success(response?.data?.message);
    return response?.data;
  }
};

// get maintenance List
export const getAllMaintenanceList = () => async (dispatch) => {
  const response = await APIService.doGet(`/admin/get-maintenance`);
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// add resolve remark api
export const addMaintenanceResolveRemark = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin/add-maintenance", data);
    if (response?.data?.status === 201 || response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

//admin get geo location
export const getLocation = (location) => async (dispatch) => {
  dispatch({
    type: type.GET_LOCATION,
    payload: location,
  });
};
//Task Management

//Add Task
// get all task api
export const getAllTasks = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin/get-all-task", data);
    if (response?.data?.status === 201 || response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
// get all task api
export const getSingleTask = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin/get-task", data);
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
// add edit task api
export const addEditTask = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin/add-edit-task", data);
    if (response?.data?.status === 201 || response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
// delete task api
export const deleteTask = (data) => async (dispatch) => {
  try {
    const response = await APIService.doPost("/admin/delete-task", data);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// get directory
export const getDirectoryList = (values) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-all-directory", values);
  return response?.data;
};

// add directory
export const addDirectory = (data, setLoading) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-directory", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    setLoading(false);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
    setTimeout(() => {
      setLoading(false);
    }, 5500);
  }
};

// delete directory
export const deleteDirectory = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/delete-directory", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    dispatch(getDirectoryList());
  } else {
    toast.error(response.response.data.message);
  }
};

// get active user
export const getActiveUserList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-lat-long-data");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

export const addTankerDashboardDetails = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/add-edit-loading-tanker",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// tanker dashboard barge list
export const getTankerDashboardBarge = () => async (dispatch) => {
  // const response = await APIService.doGet("/getBargeList");
  const response = await APIService.doGet("/admin/getBargeListByCategory");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// get single barge(Craft) by id
export const getSingleDashboardTankerDetails = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-tanker-details", data);
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// // list trader list
export const getOrderTraderList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-all-trader");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_ORDER_TRADER_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// get tanker dashboard
export const getTankerDashboard = () => async (dispatch) => {
  const response = await APIService.doGet("admin/tanker-dashboard");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_TANKER_DASHBOARD_LIST,
      payload: response.data.data,
    });
  }
};

// get free and breakdown tanker api
export const getFreeBreakdownTankers = () => async (dispatch) => {
  const response = await APIService.doGet(
    "admin/free-breakdown-tanker-dashboard"
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.SET_FREE_BREAKDOWN_TANKERS,
      payload: response.data.data,
    });
  }
};

// get trip dashboard
export const getTripDashboardList = () => async (dispatch) => {
  const response = await APIService.doGet("admin/get-trip-dashboard-data");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_TRIP_DASHBOARD_LIST,
      payload: response.data.data,
    });
  }
};

//Tanker Trips

// get All Tanker Trips List
export const getAllTankerTripsList = () => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-all-trips-of-tanker");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

export const resetTankerDate = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/reset-loading-data", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//get tug name of vessel
export const getTugNameOfVessel = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-tug-vessel-name");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_TUG_NAME_OF_VESSEL_LIST_SUCCESS,
      payload: response?.data?.data,
    });
  }
};

// list port
export const getTugOrderPortList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-tug-port-list");
  if (response?.data?.status === 200) {
    dispatch({
      type: type.TUG_ORDER_PORT_LIST_SUCCESS,
      payload: response.data.data,
    });
    return response?.data;
  }
};

// add tug order
export const addTugOrder = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-tug-order", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // navigate("/admin/tugsOrder");
    return response?.data;
  }
};

// // getbarge category wise
// export const getBargeListByCategory = (id, navigate) => async (dispatch) => {
//   const response = id
//     ? await APIService.doGetQueryParams("/admin/getBargeListByCategory", {
//         params: { portId: id },
//       })
//     : await APIService.doGetQueryParams("/admin/getBargeListByCategory");

//   return response?.data;
// };

// get tug order list
export const getTugsOrderList = (tugType, navigate) => async (dispatch) => {
  const response = await APIService.doGetQueryParams(
    "/admin/get-tug-order-list",
    {
      params: { type: tugType },
    }
  );

  return response?.data;
};

//get single  tug order
export const getSingleTugOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-single-tug-order-data",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
  } else {
    toast.error(response?.response?.data?.message);
  }
  return response?.data;
};

//all muliple tug cancel order
export const allMultipleTugsCancelOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/update-multiple-tug-order",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    return response?.data?.status;
  } else {
    toast.success(response?.data?.message);
  }
};

// order tug completed
export const tugOrderMultipleCompleted = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/tug-order-completed", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getOrder());
    return response?.data?.status;
  } else {
    toast.error(response?.data?.message);
  }
};

//get challan list
// export const getTugChallanList = (data) => async (dispatch) => {
//   const response = await APIService.doPost("/admin/get-tug-challan-list", data);
//   if (response?.data?.status === 200 || response?.data?.status === 201) {
//     return response?.data;
//   } else {
//     toast.error(response?.response?.data?.message);
//   }
// };

export const getTugChallanList = (id, navigate) => async (dispatch) => {
  const response = await APIService.doGetQueryParams(
    "/admin/get-tug-challan-list",
    {
      params: { tugOrderId: id },
    }
  );

  return response?.data;
};

//post tug  challan status
export const addTugChallanStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/tug-challan-status", data);
  if (response?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

export const getSingleTugChallanEditRequest =
  (id, navigate) => async (dispatch) => {
    const response = await APIService.doGetQueryParams(
      "/admin/get-challan-request",
      {
        params: { id: id },
      }
    );

    return response?.data;
  };

//post tug  challan request status
export const addTugChallanEditReqStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/approve-reject-tug-challan-request",
    data
  );
  if (response?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// // //get Single tug order  Challan EditRequest
// export const getSingleTugChallanTugTiming =
//   (data, navigate) => async (dispatch) => {
//     const response = await APIService.doPost(
//       "/admin/get-tug-challan-list",
//       data
//     );
//     if (response?.data?.status === 200 || response?.data?.status === 201) {
//       return response?.data;
//     } else {
//       toast.error(response?.response?.data?.message);
//     }
//   };

//edit bunker timing
export const addEditTugTiming = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/upload-edit-tug-challan",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getInquiryList());
    // navigate(`/admin/inquiry`);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//post cancel  order( nominator )
export const cancelSingleTugOrder = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/cancel-single-tug-order",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    navigate("/admin/tugsOrder");
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// Water Supply Order

//Get water supply order >>>>>>>>>>>>

// get water supply order list
export const getWaterSupplyOrderList = (type) => async (dispatch) => {
  const response = await APIService.doGetQueryParams(
    "/admin/get-supply-order-list",
    {
      params: { type: type },
    }
  );
  return response?.data;
};

// get supply Sludge order list
export const getSlopeSludgeOrderList =
  (tugType, navigate) => async (dispatch) => {
    const response = await APIService.doGetQueryParams(
      "/admin/get-slope-order-list",
      {
        params: { type: tugType },
      }
    );

    return response?.data;
  };

// add water supply order list
export const addEditWaterSupplyOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/add-edit-supply-order",
    data
  );

  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
  }

  return response?.data;
};

// update multiple water supply order status
export const updateMultipleWaterSupplyOrderStatus =
  (data) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/update-multiple-supply-order",
      data
    );

    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    }

    return response?.data;
  };

// update multiple water supply order status
export const updateCompletedMultipleWaterSupplyStatus =
  (data) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/supply-order-completed",
      data
    );

    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    }

    return response?.data;
  };

// get water supply port list
export const getWaterSupplyPortList = (data) => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-supply-port-list");

  return response?.data;
};

// get water supply vessel name list
export const getWaterSupplyVesselNameList = (data) => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-supply-vessel-name");

  return response?.data;
};

// get single water supply order
export const getSingleWaterSupplyOrder = (id) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-single-supply-order-data",
    id
  );

  return response?.data;
};

// get water supply order challan list
export const getWaterSupplyChallanList = (id) => async (dispatch) => {
  const response = await APIService.doGetQueryParams(
    "/admin/get-supply-challan-list",
    {
      params: { supplyOrderId: id },
    }
  );

  return response?.data;
};

// get water supply order status
export const waterSupplyChallanStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/supply-challan-status",
    data
  );
  if (response?.data?.status === 200) {
    toast.success(response?.data?.message);
  }
  return response?.data;
};

// get water supply order challan edit request list
export const getWaterSupplyChallanEditRequestList =
  (id) => async (dispatch) => {
    const response = await APIService.doGetQueryParams(
      "/admin/get-supply-challan-request",
      { params: { id: id } }
    );
    return response?.data;
  };

// get water supply order status
export const waterSupplyChallanEditRequestStatus =
  (data) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/approve-reject-supply-challan-request",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    }
    return response?.data;
  };

// get water supply order activity log list
export const getWaterSupplyActivityLogList = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/get-activity-log", data);
  return response?.data;
};

// get water supply order activity log list
export const editWaterSupplyChallan = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/upload-edit-supply-challan",
    data
  );
  return response?.data;
};

// cancel single water supply order
export const cancelSingleWaterSupplyOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/cancel-single-supply-order",
    data
  );
  if (response?.data?.status === 200) {
    toast.success(response?.data?.message);
  }
  return response?.data;
};

// slope sludge addorder
export const addOrderSlopeSludge = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/add-edit-slope-order", data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  }
  // else {
  //   toast.error(response.response.data.message);
  // }
};

//get tug name of vessel
export const getSlopeSludgeOfVessel = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-slope-vessel-name");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

// list slope port
export const getSlopeSludgeOrderPortList = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-slope-port-list");
  if (response?.data?.status === 200) {
    return response?.data;
  }
};

//all all Multiple Slope CancelOrder
export const allMultipleSlopeCancelOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/update-multiple-slope-order",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response?.data?.message);
    return response?.data?.status;
  } else {
    toast.success(response?.data?.message);
  }
};

// slope Order MultipleCompleted  completed
export const slopeOrderMultipleCompleted = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/slope-order-completed",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    // dispatch(getOrder());
    return response?.data?.status;
  } else {
    toast.error(response?.data?.message);
  }
};

// get single slope sludge order
export const getSingleSlopeSludgeOrder = (id) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/get-single-slope-order-data",
    id
  );

  return response?.data;
};

// get slope order challan list
export const getSlopeSludgeChallanList = (id) => async (dispatch) => {
  const response = await APIService.doGetQueryParams(
    "/admin/get-slope-challan-list",
    {
      params: { slopeOrderId: id },
    }
  );
  return response?.data;
};

// get slope Sludge Challan status
export const slopeSludgeChallanStatus = (data) => async (dispatch) => {
  const response = await APIService.doPost("/admin/slope-challan-status", data);
  if (response?.data?.status === 200) {
    toast.success(response?.data?.message);
  }
  return response?.data;
};

// get Slope Sludge ChallanEditRequestList
export const getSlopeSludgeChallanEditRequestList =
  (id) => async (dispatch) => {
    const response = await APIService.doGetQueryParams(
      "/admin/get-slope-challan-request",
      {
        params: { id: id },
      }
    );
    return response?.data;
  };

// get slope Sludge Challan EditRequestStatus
export const slopeSludgeChallanEditRequestStatus =
  (data) => async (dispatch) => {
    const response = await APIService.doPost(
      "/admin/approve-reject-slope-challan-request",
      data
    );
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    }
    return response?.data;
  };

// get water supply order activity log list
export const editSlopeSludgeTiming = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/upload-edit-slope-challan",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.data?.message);
  }
};

// cancel Single Slope SludgeOrder  order
export const cancelSingleSlopeSludgeOrder = (data) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/cancel-single-slope-order",
    data
  );
  if (response?.data?.status === 200) {
    toast.success(response?.data?.message);
  }
  return response?.data;
};

// get PipeLine details
export const getSingleBunkerPipeLineDetails = (id) => async (dispatch) => {
  const response = await APIService.doGetQueryParams(
    "/admin/get-single-pipeline-details",
    {
      params: { orderId: id },
    }
  );
  return response?.data;
};

//edit pipeline details
export const addEditPipeLineDetails = (data, navigate) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/add-edit-pipeline-order",
    data
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

export const getDirectIndirectExpenseTotal = (data) => async (dispatch) => {
  const response = await APIService.doGet(
    `/admin/get-direct-indirect-purchase?companyName=${data}`
  );
  if (response?.data?.status === 200) {
    dispatch({
      type: type.GET_EXPENSE_TOTAL,
      payload: response.data.data,
    });
  } else {
    dispatch({
      type: type.GET_EXPENSE_TOTAL,
      payload: {
        directExpenseTotal: 0,
        indirectExpenseTotal: 0,
      },
    });
  }
};

// Free dashboard tanker
export const freeDashboardTanker = (id) => async (dispatch) => {
  const response = await APIService.doPost("/admin/free-tanker", id);
  if (response?.data?.status === 200) {
    toast.success(response?.data?.message);
  }
  return response?.data;
};

// get tugs data
export const getTugsList = (values) => async () => {
  const response = await APIService.doPost("/admin/get-tug-order", values);
  return response?.data;
};

// when the update tug's order inside the invoice tab then used...
export const updateTugsOrderStatus = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/update-tugs-order-invoice-status",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// get water supply data
export const getWaterSupplyList = (values) => async () => {
  const response = await APIService.doPost("/admin/supply-order-list", values);
  return response?.data;
};

// when the update water supply's order inside the invoice tab then used...
export const updateWaterSupplyOrderStatus = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/update-supply-order-invoice-status",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// get slop and slug data
export const getSlopAndSlugList = (values) => async () => {
  const response = await APIService.doPost("/admin/slope-order-list", values);
  return response?.data;
};

// when the update water supply's order inside the invoice tab then used...
export const updateSlopAndSlugOrderStatus = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/update-slopeorder-invoice-status",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the bunker's order inside the invoice tab then used...
export const updateBunkerOrderStatus = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/update-order-invoice-status",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// get remaining invoice

export const getInvoiceReminder = (values) => async () => {
  const response = await APIService.doGet(
    "/admin/remaining-invoice-order",
    values
  );
  return response.data;
};

// Dashboard Tanker Breakdown list
export const getDashboardTankerBreakdownList = () => async () => {
  const response = await APIService.doGet("/admin/breakdown-tanker-list");
  return response.data;
};

// Dashboard Craft Breakdown list
export const getDashboardCraftBreakdownList = () => async () => {
  const response = await APIService.doGet("/admin/breakdown-craft-list");
  return response.data;
};

// when the user add bank's data for dashboard basis then used...
export const addBankDashboardBankAmount = (values) => async () => {
  const response = await APIService.doPost("/admin/add-bank-amount", values);
  return response.data;
};

// when the user get the bank's amount then used...
export const getDashboardBankAmountDetails = () => async (dispatch) => {
  const response = await APIService.doGet("/admin/get-bank-data");
  dispatch({
    type: type.GET_DASHBOARD_BANK_AMOUNTS,
    payload: response.data.data,
  });
  return response.data;
};

// when the user add the preventive then used...
export const addEditCraftPreventive = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    "/admin/add-edit-preventive",
    values
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response?.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the assign user to preventive then used...
export const assignToCraftPreventive = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/assign-barge-to-preventive",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the get preventive listing then used...
export const getCraftPreventiveList = (values) => async () => {
  const response = await APIService.doGet("/admin/get-preventive-data", values);
  return response.data;
};

// When the use remove the preventive then used...
export const removeCraftPreventive = (values) => async () => {
  const response = await APIService.doPost("/admin/delete-preventive", values);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

//  when the prevention side add assign to then get all the barge
export const getAllBargeList = () => async () => {
  const response = await APIService.doGet("/admin/barge-list");
  return response.data;
};

// when the user get the maintenance side prevention listing then used...
export const getAllMaintenancePreventionList = () => async () => {
  const response = await APIService.doPost("/admin/get-preventive-report");
  return response.data;
};

// whent the get all the prevention responsiblity then used...
export const getAllPreventionResponsibilityList = () => async () => {
  const response = await APIService.doGet("/admin/get-responsibility");
  return response.data;
};

// whent the get all the prevention sub head by responsibility id wise then used...
export const getAllPreventionSubHeadByIdList = (values) => async () => {
  const response = await APIService.doPost("/admin/get-subhead", values);
  return response.data;
};

// whent the get all the prevention sub head then used...
export const getAllPreventionSubHeadList = (values) => async () => {
  const response = await APIService.doGet("/admin/get-all-subhead", values);
  return response.data;
};

// when the get responsibility wise get prevention data then used...
export const getResponsiblityWisePrevention = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/preventive-data",
    // "/admin/get-preventive-list",
    values
  );
  return response.data;
};

// when the get all preventive list wise report then used...
export const getAllHeadPreventiveWiseReport = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/preventive-all-data",
    values
  );
  return response.data;
};

// when the get document center side document's listing then used...
export const getDocumentCentersDocumentList = (values) => async () => {
  const response = await APIService.doPost("/admin/get-document", values);
  return response.data;
};

// when the user add the status on document side then used...
export const addStatusOnDocumentSide = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/update-document-status",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the get document listing in dashboard side  then used...
export const getDashboardDocumentList = (values) => async () => {
  const response = await APIService.doGet(`/admin/craft-document-dashboard`);
  return response.data;
};

// when the get dashboard side kotak bank data then used.....
export const getDashboardKOTAKBankList = (values) => async () => {
  const response = await APIService.doPost(`/admin/get-kotak`);
  return response.data;
};

// when the get dashboard side hdfc bank data then used.....
export const getDashboardHDFCBankList = (values) => async () => {
  const response = await APIService.doPost(`/admin/get-hdfc`, values);
  return response.data;
};

// when the get the number of invoice when the user change the date then used....
export const getNewInvoiceNumber = (values) => async () => {
  const response = await APIService.doPost("/admin/get-invoice-number", values);
  return response.data;
};

// when the get all barge for tug basis then used....
export const getBargeListForTugs = () => async () => {
  const response = await APIService.doGet("/admin/get-barge-list-for-tug");
  return response.data;
};

// when the user get the preventive log then used....
export const getPreventiveLogDetails = (values) => async () => {
  const response = await APIService.doPost("/admin/preventive-log", values);
  return response.data;
};

// when the user document center's remark history log then used....
export const getDocumentsRemarkHistoryLog = (values) => async () => {
  const response = await APIService.doGet(
    `/admin/get-craft-remark?id=${values}`,
    values
  );
  return response.data;
};

// get-documents-history

// when the user document's history log then used....
export const getDocumentsHistory = (values) => async () => {
  const response = await APIService.doGet(
    `/admin/get-documents-history?id=${values}`
  );
  return response.data;
};

// when the user add history of docuemnts then used...
export const addDocumentHistory = (values) => async () => {
  const response = await APIService.doPost(`/admin/add-new-documents`, values);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the user get the terminal then used...
export const getAllTerminalList = () => async () => {
  const response = await APIService.doGet("/admin/get-terminal");
  return response.data;
};

// when the show all the tankers on map side then used...
export const getAllTankersForMaps = () => async () => {
  const response = await APIService.doGet("/admin/get-tanker-list");
  return response.data;
};

// when the user want transfer's data or listing then used...
export const getAllTransferDetails = (values) => async () => {
  const response = await APIService.doPost("/admin/get-transfer-list", values);
  return response.data;
};

// when the we have to get the Order wise transfer then used...
export const getOrderWiseTransferDetails = (values) => async () => {
  const response = await APIService.doGet(
    `/admin/order-transfer-list?orderId=${values}`
  );
  return response.data;
};

// when the user get the prevetive listing for view wise then used...
export const getPreventiveListForViewWise = () => async () => {
  const response = await APIService.doPost(`/admin/prevenitve-data-for-list`);
  return response.data;
};

// when the user assign the craft in preventive then used...
// when the assign user to preventive then used...
export const assignToBargeInPreventive = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/assign-to-barge-in-preventive",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the user remove the assigned craft in preventive then used...
export const removedAssignedBargeToPreventive = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/remove-to-barg-in-preventive",
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the user get the prevetive listing for view wise then used...
export const getPreventiveListForTestPurpose = () => async () => {
  const response = await APIService.doGet(`/admin/get-preventive-test`);
  return response.data;
};

// when the user add the subHead then used....
export const addPreventiveSubHead = (values) => async () => {
  const response = await APIService.doPost(`/admin/add-edit-subhead`, values);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// when the user get the all Craft's Breakdown list then used...
export const getCraftBreakdownReacords = (values) => async () => {
  const response = await APIService.doPost(
    `/admin/get-maintenance-list`,
    values
  );
  return response.data;
};

// when the get all company list then used...
export const getAllCompanyList = () => async () => {
  const response = await APIService.doGet(`/admin/get-company`);
  return response.data;
};

// when the dispatch through set the company
export const adminSelectCompany = (data) => async (dispatch) => {
  dispatch({
    type: type.ADMIN_ACTIVE_COMPANY_NAME,
    payload: data,
  });
};

// dispatched the craft for craft breakdown maintanance
export const adminDispatchCraft = (data) => async () => {
  const response = await APIService.doPost(`/admin/update-timestamp`, data);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// when the user fetch the job type wise dashboard side data then used...
export const getJobTypeWiseDashboardData = (data) => async () => {
  const response = await APIService.doGet(
    `/admin/get-type-maintenance?jobType=${data}`
  );
  return response.data;
};

// when the user direct Break-In and Break-Out then used...
export const adminDirectBreakInOutBarge = (values) => async () => {
  const response = await APIService.doPost(
    `/admin/update-breakdown-in-out`,
    values
  );

  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

// when the user want to get craft wise breakdown list then used...
export const getCraftWiseBreakDownList = (values) => async () => {
  const response = await APIService.doPost(
    `/admin/get-craft-wise-maintenance-list`,
    values
  );
  return response.data;
};

// when the user direct Break-In and Break-Out then used...
export const adminDirectRejectBreakDownRequest = (value) => async () => {
  const response = await APIService.doGet(
    `/admin/reject-maintenance-request?id=${value}`
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
};

//  when the prevention side add assign to then get all the barge is user wise
export const getAllUserWiseBargeList = () => async () => {
  const response = await APIService.doGet("/admin/barge-by-company");
  return response.data;
};

// when the get all companies for company module side then used...
export const getAllCompaniesList = () => async () => {
  const response = await APIService.doGet("/admin/get-company");
  return response.data;
};

export const AdminAddCompany = (values) => async () => {
  const response = await APIService.doPost("/admin/add-company", values);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the get bank histories then used...
export const getBankHistories = (values) => async () => {
  const response = await APIService.doPost("/admin/get-bank-history", values);
  return response.data;
};

// when the get bank histories then used...
export const getCompanyTroughTallyBanks = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/bank-list-as-per-tally",
    values
  );
  return response.data;
};

// when the get bank histories then used...
export const getTallyBankThorughBankRecords = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/bank-records-as-per-company",
    values
  );
  return response.data;
};

// when the get barge fresh waters wise then used...
export const getFreshWaterBargeList = (values) => async () => {
  const response = await APIService.doGet("/getFreshWaterBargeList", values);
  return response.data;
};

// when the get pipeline surveyour supplies data then used...
export const getPipelineSurveyourSuppliesList = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/get-pipeline-orders",
    values
  );
  return response.data;
};

// when the get surveyour supplies data then used...
export const getSurveyourSuppliesList = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/get-surveryour-supplies-orders",
    values
  );
  return response.data;
};

// when the get purchase selection list then used...
export const getPurchaseSelectionList = (values) => async () => {
  const response = await APIService.doPost("/admin/get-ledger-data", values);
  return response.data;
};

// when the update purchase and journal conditions then used....
export const updatePurchaseSelectionList = (values) => async () => {
  const response = await APIService.doPost("/admin/update-ledger-data", values);
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the get updated purchase options then used...
export const getUpdatedPurchaseSelectionList = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/get-managed-purchase",
    values
  );
  return response.data;
};

// when the get latest new purchase list then used...
export const getNewPurchaseList = (values) => async () => {
  const response = await APIService.doPost("/admin/new-purchase", values);
  return response.data;
};

// when the get latest new journal list then used...
export const getNewJournalList = (values) => async () => {
  const response = await APIService.doPost("/admin/new-journal", values);
  return response.data;
};

// when the get new bank list data then used...
export const getNewBankList = (values) => async () => {
  const response = await APIService.doGet("/admin/bank-list");
  return response.data;
};

// when the get companies side bank details then used...
export const getCompaniesWiseBankDetails = (values) => async () => {
  const response = await APIService.doPost("/admin/bank-tab", values);
  return response.data;
};

// whent the get accounting data as per tally invoice basis then used...
export const getAccountingDataAsPerTallyInvoice = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/acounting-data-as-per-invoice",
    values
  );
  return response.data;
};

// when the get bank dashboard data then used...
export const getBankDashboardDetails = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/bank-dashboard-as-per-company",
    values
  );
  return response.data;
};

// when the get dashboard bank data then used...
export const getDashboardBankDetails = (values) => async () => {
  const response = await APIService.doGet(
    `/admin/tally-bank-list?companyName=${values}`
  );
  return response.data;
};

// when the get dashboard side all bank transactions data then used...
export const getDashboardAllTransactionsData = (values) => async () => {
  const response = await APIService.doGet(
    `/admin/bank-transaction?companyName=${values}`
  );
  return response.data;
};

// when the find next current order then used...
export const getNextCurrentOrder = (values) => async () => {
  const response = await APIService.doPost("/admin/next-current-order", values);
  if (response.data?.status === 200) {
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the get shift tanker's log then used....
export const getShiftTankersLog = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/shift-tanker-log-by-id",
    values
  );
  return response.data;
};

// when the update perticular field then used...
export const handleGetUpdateSingleOrderField = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/add-edit-order-single-field",
    values
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the update tug order's  perticular field then used...
export const handleGetUpdateSingleTugOrderField = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/single-field-add-edit-tug-order",
    values
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the update water supply order's  perticular field then used...
export const handleGetUpdateSingleWaterSupplyOrderField =
  (values) => async () => {
    const response = await APIService.doPost(
      "/admin/single-field-edit-supply-order",
      values
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      return response.data;
    } else {
      toast.error(response.response.data.message);
    }
  };

// when the update slop and sludge supply order's  perticular field then used...
export const handleGetUpdateSingleSlopAndSludgeOrderField =
  (values) => async () => {
    const response = await APIService.doPost(
      "/admin/single-field-edit-slope-order",
      values
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      return response.data;
    } else {
      toast.error(response.response.data.message);
    }
  };

// when the user's lat long hide and show then used...
export const handleUserLatLongHideAndShow = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/hider-user-lat-long-data",
    values
  );
  if (response?.data?.status === 200 || response?.data?.status === 201) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the get fresh water type tugs list then used...
export const getFreshWaterTypedTugs = () => async () => {
  const response = await APIService.doPost("/admin/hider-user-lat-long-data");
  return response.data;
};

// when the get tally barge wise list for dashboard then used...
export const getDashboardBargeTallyWise = (data) => async () => {
  const response = await APIService.doGet(
    `/admin/tally-barge-wise-list?${data}`
  );
  return response.data;
};

// when the get tally category wise list for dashboard then used...
export const getDashboardCategoryWise = (data) => async () => {
  const response = await APIService.doGet(`/admin/category-wise?${data}`);
  return response.data;
};

// when the get tally category wise list for dashboard then used...
export const getDashboardCategoryTallyWise = (data) => async () => {
  const response = await APIService.doGet(`/admin/category-tally-wise?${data}`);
  return response.data;
};

// when the user show category wise tally basis  all the invoice or recores then used..
export const getCategoryWiseAllTallyInvoices = (values) => async (dispatch) => {
  const response = await APIService.doPost(
    `admin/category-wise-tally-list`,
    values
  );
  if (response?.data?.status === 200) {
    return response?.data;
  } else {
    toast.error(response.data.message);
  }
};

// when the get sales from tally wise then used...
export const getSalesPartyTallyWise = (data) => async () => {
  const response = await APIService.doGet(
    `/admin/sales-party-tally-wise?${data}`
  );
  return response.data;
};

// when the get tally wise monthly sales data then used...
export const getTallyWiseMonthlySalesData = (values) => async () => {
  const response = await APIService.doGet(
    `/admin/tally-monthly-sales?companyName=${values}`
  );
  return response.data;
};

// when the get tally wise monthly purchase data then used...
export const getTallyWiseMonthlyPurchaseData = (values) => async () => {
  const response = await APIService.doGet(
    `/admin/tally-monthly-purchase?companyName=${values}`
  );
  return response.data;
};

// when the user sync the data over tally side then used...
export const getSyncTallyData = () => async (dispatch) => {
  const response = await APIService.doGet(`/admin/tally-csv-sync`);
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
  } else {
    toast.success(response.data.message);
  }
  return response.data;
};

// when the get beta purchase tally invoice details then used...
export const getBetaPurchaseTallyInvoiceDetails =
  (values) => async (dispatch) => {
    const response = await APIService.doPost(`/admin/new-sub-purchase`, values);
    return response?.data;
  };

// when the get beta journal tally invoice details then used...
export const getBetaJournalTallyInvoiceDetails = (values) => async () => {
  const response = await APIService.doPost("/admin/new-sub-journal", values);
  return response.data;
};

// when the get the due invoice details then used...
export const getTallyDueInvoiceDetailsByDateWise =
  (values) => async (dispatch) => {
    const response = await APIService.doPost(
      `/admin/tally-barge-wise-sales-for-invoice`,
      values
    );
    return response.data;
  };

// when the get the banks transactions then used...
export const getBanksTransactions = (values) => async () => {
  const response = await APIService.doPost(
    "/admin/bank-transaction-as-per-company",
    values
  );
  return response.data;
};
