import { useCallback, useEffect, useState } from "react";
import Index from "../../../../Index";
import {
  getAllCompanyList,
  getBankDashboardDetails,
} from "../../../../../redux/admin/action";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs from "dayjs";
import moment from "moment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function BankDashboard() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(
    "Ms. Bapus Shipping Jamnagar Pvt. Ltd. (15-18)"
  );
  const [bankDetails, setBankDetails] = useState([]);
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");

  // Current date information
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  // Utility to generate financial years dynamically
  const getFinancialYearsWithStartDates = (startYear) => {
    const currentFinancialYearStart =
      currentMonth < 3 ? currentYear - 1 : currentYear;

    return Array.from(
      { length: currentFinancialYearStart - startYear + 1 },
      (_, index) => {
        const year = startYear + index;
        // Return the financial year start date in YYYY-MM-DD format
        return {
          label: `${year} - ${year + 1}`,
          value: `${year}-04-01`, // Start date in YYYY-MM-DD format
        };
      }
    );
  };

  // Financial year data
  const startYear = 2023;
  const financialYears = getFinancialYearsWithStartDates(startYear);

  // Reusable function to format API request payload
  const getFormattedValues = (company, start, end) => ({
    companyName: company,
    startDate: start ? moment(start).format("YYYY-MM-DD") : "",
    endDate: end ? moment(end).format("YYYY-MM-DD") : "",
  });

  // Fetch all companies
  const handleGetAllCompanyList = async () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setCompanyList(res.data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setCompanyList([]);
      });
  };

  // Fetch bank details
  const handleGetBankDetails = (values) => {
    setLoading(true);
    dispatch(getBankDashboardDetails(values))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setBankDetails(res.data);
          setLoading(false);
        } else {
          setBankDetails([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setBankDetails([]);
        setLoading(false);
      });
  };

  // Initialize data on component load
  useEffect(() => {
    handleGetAllCompanyList();

    // Set default financial year
    const currentFinancialYearStart =
      currentMonth < 3 ? currentYear - 1 : currentYear;
    const currentFinancialYear = financialYears.find((fy) =>
      fy.label.startsWith(currentFinancialYearStart.toString())
    );
    if (currentFinancialYear) {
      setSelectedFinancialYear(currentFinancialYear.value);
    }

    // Initial bank details fetch
    const initialValues = getFormattedValues(
      selectedCompany,
      `${currentFinancialYearStart}-04-01`,
      endDate
    );
    handleGetBankDetails(initialValues);
  }, []);

  // Handle company change
  const handleChangeCompany = useCallback(
    (value) => {
      setSelectedCompany(value);
      const updatedValues = getFormattedValues(
        value,
        selectedFinancialYear,
        endDate.format("YYYY-MM-DD")
      );
      handleGetBankDetails(updatedValues);
    },
    [selectedFinancialYear, endDate]
  );

  // Handle date range change
  const handleChangeDateRange = useCallback(
    (data) => {
      setEndDate(data);
      const updatedValues = getFormattedValues(
        selectedCompany,
        selectedFinancialYear,
        data.format("YYYY-MM-DD")
      );
      handleGetBankDetails(updatedValues);
    },
    [selectedCompany, selectedFinancialYear]
  );

  // Handle financial year change
  const handleChangeFinancialYear = useCallback(
    (event) => {
      const yearValue = event.target.value;
      setSelectedFinancialYear(yearValue);
      const updatedValues = getFormattedValues(
        selectedCompany,
        yearValue,
        endDate.format("YYYY-MM-DD")
      );
      handleGetBankDetails(updatedValues);
    },
    [endDate, selectedCompany]
  );

  return (
    <>
      <Index.DynamicTitle title="Bank Dashboard" />
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex  res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Bank Dashboard
              </Index.Typography>
            </Index.Box>
            <Index.Box className="d-flex align-items-center res-set-search">
              <Index.Box>
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    className="placeholder-set-text-box"
                    fullWidth
                    name="companyId"
                    displayEmpty
                    inputProps={{ "aria-label": "without label" }}
                    value={selectedFinancialYear}
                    MenuProps={MenuProps}
                    onChange={(event) => handleChangeFinancialYear(event)}
                  >
                    <Index.MenuItem value={""}>
                      <Index.MenuItem disabled className="all-select-label">
                        Select Company
                      </Index.MenuItem>
                    </Index.MenuItem>

                    {financialYears?.map((item) => (
                      <Index.MenuItem key={item?._id} value={item.value}>
                        {item.label}
                      </Index.MenuItem>
                    ))}
                  </Index.Select>
                </Index.Box>
              </Index.Box>
              <Index.Box className="set-date set-date-picker remove-name">
                <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                  <Index.DemoContainer components={["DatePicker"]}>
                    <Index.DemoItem label="">
                      <Index.DatePicker
                        name="endDate"
                        format="DD/MM/YYYY"
                        className="cus-datepicker"
                        value={endDate}
                        onChange={(value) => handleChangeDateRange(value)}
                        slotProps={{
                          textField: {
                            readOnly: true,
                          },
                        }}
                        minDate={
                          selectedFinancialYear?.length
                            ? dayjs(selectedFinancialYear, "DD-MM-YYYY") // Convert start date to a valid dayjs object
                            : undefined
                        }
                        disableFuture
                      />
                    </Index.DemoItem>
                  </Index.DemoContainer>
                </Index.LocalizationProvider>
              </Index.Box>
              <Index.Box>
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    className="placeholder-set-text-box"
                    fullWidth
                    name="companyId"
                    displayEmpty
                    inputProps={{ "aria-label": "without label" }}
                    value={selectedCompany}
                    MenuProps={MenuProps}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      setSelectedCompany(value);
                      handleChangeCompany(value);
                    }}
                  >
                    <Index.MenuItem value={""}>
                      <Index.MenuItem disabled className="all-select-label">
                        Select Company
                      </Index.MenuItem>
                    </Index.MenuItem>

                    {companyList?.map((item) => (
                      <Index.MenuItem
                        key={item?._id}
                        value={item?.tallyCompanyName}
                      >
                        {item?.tallyCompanyName}
                      </Index.MenuItem>
                    ))}
                  </Index.Select>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {loading ? (
          <>
            <Index.Box className="bank-dash-loader">
              <Index.CircularProgress color="secondary" size={20} />
            </Index.Box>
          </>
        ) : (
          <>
            <Index.Box>
              {bankDetails?.length > 0 ? (
                <>
                  {bankDetails?.map((bankData) => (
                    <Index.Box className="barge-common-box box-margin">
                      <Index.Box className="bank-box bank-flex-box">
                        <Index.Typography className="bank-name">
                          {bankData?.name}
                        </Index.Typography>
                        <Index.Box className="common-button grey-button">
                          <Index.Button
                            variant="contained"
                            onClick={() => {
                              window.open(
                                `/admin/companyDashboard/transaction?companyName=${selectedCompany}&&ledgerId=${bankData?.ledger_guid}`,
                                "_blank"
                              );
                            }}
                          >
                            Transactions
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="mini-card-main mini-card-main-bank-details card-wrapper">
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Opening Balance
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.openingBalance.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Total Payment
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {parseInt(bankData?.paymentTotal).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Total Receipt
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.receiptTotal.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Total Contra
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.contraTotal.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Closing Balance
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.closingBalance.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ))}
                </>
              ) : (
                <>
                  <Index.Box className="no-data-box">
                    No data available
                  </Index.Box>
                </>
              )}
            </Index.Box>
          </>
        )}
      </Index.Box>
    </>
  );
}
export default BankDashboard;
